<template>
  <div>
    <v-data-table
      :headers="dessertHeaders"
      :items="getContactMessage"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      show-expand
      class="elevation-1"
      :items-per-page="20"
      hide-default-footer
      :loading="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item.message }}
        </td>
      </template>
    </v-data-table>
    <div class="text-center mt-7">
      <v-pagination v-model="page" :length="totalItems" circle></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      currentPage: 1,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Full Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "Email", value: "email", sortable: false },
        { text: "Join", value: "createdAt", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ],
    };
  },
  computed: {
    getContactMessage() {
      return this.$store.getters.getContactMessage;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  methods: {
    GetAllUser() {
      let payload = {
        token: this.getToken,
        page: this.page,
      };
      this.$store.dispatch("GetContactMessage", payload);
    },
  },
  created() {
    this.GetAllUser();
  },
  watch: {
    page: function (val) {
      if (this.page != this.currentPage) {
        this.currentPage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("GetContactMessage", payload);
        return;
      }
    },
  },
};
</script>